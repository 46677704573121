import { ReactNode, useMemo } from 'react'
import Slider, { Settings } from 'react-slick'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import CardLink from '../CardLink/CardLink'
import { GAEvent } from '../../types'
import MarkdownText from '../MarkdownText/MarkdownText'
import './SliderSection.scss'

type Slides = {
  logo: string | IGatsbyImageData
  logoAltText: string
  description?: string
  link: string
  gaEvent?: GAEvent
}[]

interface ISliderSectionProps {
  title: string
  subtitle?: string
  description?: string
  slides: Slides
  simplePartnerCards?: boolean
}

const SliderSection = ({
  title,
  subtitle,
  description,
  slides,
  simplePartnerCards = false,
}: ISliderSectionProps) => {
  if (!slides || slides.length === 0) return null

  const slickSettings: Settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    accessibility: true,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const CardContainer = ({
    children,
    slides,
  }: {
    children: ReactNode
    slides: Slides
  }) => {
    if (slides.length > 3) {
      return (
        <div className="my-10">
          <Slider {...slickSettings}>{children}</Slider>
        </div>
      )
    } else return <div className="CardContainer">{children}</div>
  }

  return (
    <section className="SliderSection">
      <div className="text-center">
        <h2>{title}</h2>
        {subtitle && <h3 className="px-8">{subtitle}</h3>}
        <div className="SliderSection__Container">
          <CardContainer slides={slides}>
            {useMemo(
              () =>
                slides.map(
                  (
                    { logo, logoAltText, description, link, gaEvent },
                    index
                  ) => {
                    return (
                      <div
                        key={`${description}-${index}`}
                        className={`Slide ${
                          simplePartnerCards ? 'h-[200px]' : 'h-[320px]'
                        }`}
                      >
                        <CardLink
                          link={link}
                          icon={logo}
                          alt={logoAltText}
                          description={description}
                          className={` ${
                            simplePartnerCards
                              ? 'SliderSectionCard--no-description'
                              : 'SliderSectionCard'
                          }`}
                          linkText={
                            simplePartnerCards ? logoAltText : 'Learn more'
                          }
                          simplePartnerCards={simplePartnerCards}
                          gaEvent={gaEvent}
                          isCarouselSlide={true}
                        />
                      </div>
                    )
                  }
                ),
              [slides]
            )}
          </CardContainer>
        </div>
        {description && (
          <MarkdownText
            text={description}
            className="SliderSection__description"
          />
        )}
      </div>
    </section>
  )
}

export default SliderSection
