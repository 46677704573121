import LinkComponent from '../LinkComponent/LinkComponent'
import Slider, { Settings } from 'react-slick'
import home_page from '../../content/home_page'
import videos from '../../content/qml/videos'
import VideoCardInfo from '../VideoCardInfo/VideoCardInfo'
import InformationCard from '../InformationCard/InformationCard'
import './GettingStartedSection.scss'

const GettingStartedSection = () => {
  const gettingStarted = home_page.getting_started_section

  const slickSettings: Settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    accessibility: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          slidesToShow: 2,
          swipe: true,
          infinite: true,
        },
      },
      {
        breakpoint: 550,
        settings: {
          dots: false,
          slidesToShow: 1,
          swipe: true,
          infinite: true,
        },
      },
    ],
  }

  return (
    <section className="bg-background-section">
      {/* Documentation / Challenges / Codebook */}
      <div className="container py-10">
        <h2 className="text-center text-h3 mb-3">
          {gettingStarted.tutorialsTitle}
        </h2>
        <p className="text-center text-s2">
          {gettingStarted.tutorialsSubtitle}
        </p>
        <ul className="GettingStartedSection__cards">
          {gettingStarted.tutorials.map((tutorial, index) => (
            <li
              key={`tutorial-${index}`}
              className="w=full md:w-1/3 self-stretch"
            >
              <LinkComponent href={tutorial.url} className="flex h-full">
                <InformationCard
                  title={tutorial.title}
                  description={tutorial.description}
                  icon={tutorial.image}
                />
              </LinkComponent>
            </li>
          ))}
        </ul>

        {/* Videos */}
        <div className="my-10">
          <div className="flex justify-center md:justify-between items-end">
            <h3 className="text-h3 text-center md:first-letter:text-left">
              {gettingStarted.videosTitle}
            </h3>
            <div className="GettingStartedSection__video-link GettingStartedSection__video-link--desktop">
              <LinkComponent href={gettingStarted.videosLinkURL}>
                {gettingStarted.videosLinkText}
              </LinkComponent>
            </div>
          </div>
          <div className="VideoSlider__Container">
            <Slider {...slickSettings}>
              {videos.cards.slice(0, 6).map((video, index) => (
                <div key={`${video.videoId}-${index}`} className="Slide">
                  <VideoCardInfo
                    title={video.title}
                    videoId={video.videoId}
                    className="VideoCardInfo__SliderCard"
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="GettingStartedSection__video-link GettingStartedSection__video-link--mobile">
            <LinkComponent href={gettingStarted.videosLinkURL}>
              {gettingStarted.videosLinkText}
            </LinkComponent>
          </div>
        </div>
      </div>
    </section>
  )
}

export default GettingStartedSection
