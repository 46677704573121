import './InformationCard.scss'

type InfoCardV2Props = {
  title: string
  description: string
  icon: string
  className?: string
}

export default function InformationCard({
  title,
  description,
  icon,
  className = '',
}: InfoCardV2Props) {
  return (
    <div className={`InformationCard ${className}`}>
      <div className="InformationCard__icon">
        <img src={icon} alt="" />
      </div>
      <div className="InformationCard__content">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  )
}
