import { useMemo } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Hero from '../components/HeroSection/Hero'
import SpotlightSection from '../components/SpotlightSection/SpotlightSection'
import PartnersSection from '../components/PartnersSection/PartnersSection'
import GettingStartedSection from '../components/GettingStartedSection/GettingStartedSection'
import SliderSection from '../components/SliderSection/SliderSection'
import BuildWithPLSection from '../components/BuildWithPennylaneSection/BuildWithPennylane'
import Seo from '../components/seo'
import home_page from '../content/home_page'
import SubSectionLayoutV2 from '../components/SubSectionLayoutV2/SubSectionLayoutV2'
import { slugifyString } from 'shared-utilities'
import CTALink from '../components/CTALink/CTALink'
import { spotlights } from 'shared-content'
import '../scss/home-page.scss'

const DESKTOP_BACKGROUND = `https://assets.cloud.pennylane.ai/pennylane_website/backgrounds/bg_curtains.png`
const MOBILE_BACKGROUND = `https://assets.cloud.pennylane.ai/pennylane_website/backgrounds/mobile_confetti.png`

const {
  meta_title,
  meta_description,
  pennylane_used_section,
  call_to_action_section,
  second_call_to_action,
} = home_page
const { title, buttons } = home_page.building_with_pennylane
const spotlight = spotlights.home_page
export default function IndexPage() {
  const usedByPennyLaneSlides = useMemo(() => {
    return pennylane_used_section.partners.map((slide) => {
      return {
        ...slide,
        logo: slide.logo,
      }
    })
  }, [pennylane_used_section.partners])

  return (
    <div className="HomePage">
      <div className="HomePage__Hero-confetti Hero__Background__Container">
        <StaticImage
          src={DESKTOP_BACKGROUND}
          alt=""
          loading="eager"
          placeholder="none"
          className="Hero__Background__Image HomePage__Hero__confetti--desktop"
          quality={100}
        />
        <StaticImage
          src={MOBILE_BACKGROUND}
          alt=""
          loading="eager"
          placeholder="none"
          className="Hero__Background__Image HomePage__Hero__confetti--mobile"
          quality={100}
          objectFit="contain"
          objectPosition="left top"
        />
        <Hero />
        {!spotlight.hidden && (
          <SpotlightSection announcements={spotlight.announcements} />
        )}
      </div>
      <section className="flex flex-col gap-10 md:gap-20 py-10">
        {call_to_action_section.map((cta, index) => (
          <SubSectionLayoutV2
            id={slugifyString(cta?.id || cta.title)}
            key={`home-page-cta-${index}`}
            imageRightAligned={cta.image_right_aligned}
            content={
              <div className="flex flex-col justify-center h-full gap-6">
                <h2 className="text-h3">{cta.title}</h2>
                <p className="text-body mb-0">{cta.description}</p>
                <div className="flex flex-col gap-2">
                  {cta.links.map((ctaItem) => (
                    <CTALink
                      label={ctaItem.link_text}
                      href={ctaItem.link}
                      key={slugifyString(ctaItem.link_text)}
                    />
                  ))}
                </div>
              </div>
            }
            image={cta.image}
          />
        ))}
      </section>
      <PartnersSection />
      <SliderSection
        title={pennylane_used_section.title}
        slides={usedByPennyLaneSlides}
      />
      <section className="flex flex-col gap-10 md:gap-20 py-10">
        {second_call_to_action.map((cta, index) => (
          <SubSectionLayoutV2
            id={slugifyString(cta.title)}
            key={`home-page-cta-2-${index}`}
            imageRightAligned={cta.image_right_aligned}
            content={
              <div className="flex flex-col justify-center h-full gap-6">
                <h2 className="text-h3">{cta.title}</h2>
                <p className="text-body mb-0">{cta.description}</p>
                <div className="flex flex-col gap-2">
                  {cta.links.map((link, index) => (
                    <CTALink
                      key={`cta-link-${index}`}
                      label={link.link_text}
                      href={link.link}
                    />
                  ))}
                </div>
              </div>
            }
            image={cta.image}
          />
        ))}
      </section>
      <GettingStartedSection />
      <BuildWithPLSection title={title} buttons={buttons} />
    </div>
  )
}

export const Head = () => {
  return <Seo title={meta_title} description={meta_description} />
}
