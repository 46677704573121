import './SubSectionLayoutV2.scss'

type SubSectionLayoutV2Props = {
  id?: string
  content: React.ReactNode
  image: string
  alt?: string
  imageRightAligned?: boolean
}

export default function SubSectionLayoutV2({
  id,
  content,
  image,
  alt = '',
  imageRightAligned = true,
}: SubSectionLayoutV2Props) {
  return (
    <div
      className="SubSectionLayoutV2"
      id={id}
      data-testid="sub-section-layout-v2"
    >
      <div
        className={`SubSectionLayoutV2__container ${
          imageRightAligned ? 'SubSectionLayout__container--reversed' : ''
        }`}
      >
        <div
          className="SubSectionLayoutV2__image"
          data-testid="sub-section-layout-v2-image"
        >
          <img src={image} alt={alt} />
        </div>
        <div
          className="SubSectionLayoutV2__content"
          data-testid="sub-section-layout-v2-content"
        >
          {content}
        </div>
      </div>
    </div>
  )
}
